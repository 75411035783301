@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');

* {
    margin: 0;
    padding: 0;
    
    box-sizing: border-box;
}

body {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.App{
    overflow: hidden;
}

.text-blue {
    color: #08327D;
}

.btn-outline-blue {
    background-color: #fff;
    border: 1px solid #08327D;
    color: #08327D;
    border-radius: 0;
    font-size: 17px;
}

.buttonfx {
    position: relative;
    display: inline-block;
    overflow: hidden;
    transition: all .5s;
}

.buttonfx:before,
.buttonfx:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -2;
    transform: translate3D(0, -100%, 0);
    transition: all .5s;
}

.buttonfx:before {
    z-index: -1 !important;
    transform: translate3D(0, 0, 0);
}

.buttonfx:hover {
    color: white;
}

.buttonfx:hover:after {
    transform: translate3D(0, 0, 0);
    transition: all .5s;
}

/* //// Curtain Down  //// */

.curtaindown:before {
    background: #08327D;
    transform: translate3D(0, -100%, 0);
}

.curtaindown:after {
    background: #919fc1;
    transform: translate3D(0, -100%, 0);
}

.curtaindown:after {
    transform: translate3D(0, 100%, 0);
}

.curtaindown:hover:after {
    transform: translate3D(0, -50%, 0);
    transition: all .5s;
}

.curtaindown:hover:before {
    transform: translate3D(0, 0, 0);
    transition: all .8s;
}

.curtaindown:hover:after {
    transform: translate3D(0, 50%, 0);
}

.border-bottom-black {
    border-bottom: 1px solid #000;
}

a {
    text-decoration: none;
}

.page-title {
    font-size: 52px;
}

.container-margin {
    margin-top: 120px;
}

/* Start Header */

#header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 16px 0;
    background-color: #fff;
    z-index: 10;
}

#mobileHeader {
    border-bottom: 1px solid #0f0a01;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000000;
}

.services-toggle-btn.active .services-dropdown {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.header-transparent {
    background: rgba(255, 255, 255, 0.5) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

.services-dropdown {
    position: absolute;
    left: 0;
    bottom: -281px;
    background-color: #fff;
    width: 100%;
    padding: 50px 0;
}

.navbar-dropdown-list a {
    font-weight: 500;
}

.services-toggle-btn .services-dropdown {
    display: none;
}

.services-toggle-btn.active .services-dropdown {
    display: block;
}

.navbar-menu a {
    color: #000;
    font-size: 18px;
    transition: all .3s ease;
}

.navbar-menu a:hover {
    text-decoration: underline;
    color: #08327D;
}

.navbar-menu li {
    margin-right: 55px;
}

.navbar-menu .active {
    color: #08327D;
    text-decoration: underline;
}

/* end header */

/* start homepage */

#homepage,
#case-studies-inner,
#about-us-container,
#career-container,
#blog-container,
#contant-container,
#digital-marketing-container,
#e-commerce-marketing-container,
#website-development-container,
#digital-security-container,
#data-intelligence-container,
#cloud-management-container,
#creative-content-design,
#impressum-container,
#machine-learning-container,
#career-detail-container,
#products-inner-container {
    margin-top: 88px;
}

#banner {
    background-image: url('assets/img/banner-bg.png');
    background-size: cover;
    height: calc(100vh - 88px);
}

.banner-text-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.banner-alignment {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 200px;
    flex-direction: column;
}

.banner-slider-text-custom {
    font-size: 32px;
    font-weight: 500;
    color: #08327D;
}

.banner-slider-background,
.banner-slider-background-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.banner-slider-background-text {
    bottom: 50px;
    color: #fff;
}

.banner-slider {
    font-size: 36px;
    color: #08327D;
}

.banner-title {
    font-size: 62px;
}

.services-card {
    background-image: url("assets/img/services-bg.png");
    height: 300px;
    width: 100%;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease;
}

.col-xl-3:nth-child(1) .services-card {
    border-right: none;
}

.col-xl-3:nth-child(2) .services-card {
    border-right: none;
}

.col-xl-3:nth-child(3) .services-card {
    border-right: none;
}

.col-xl-3:nth-child(5) .services-card {
    border-right: none;
    border-top: none;
}

.col-xl-3:nth-child(6) .services-card {
    border-right: none;
    border-top: none;
}

.col-xl-3:nth-child(7) .services-card {
    border-right: none;
    border-top: none;
}

.col-xl-3:nth-child(8) .services-card {
    background-image: url("assets/img/comment-bg.png");
    border-top: none;
}

.services-card h5 {
    font-size: 46px;
    font-weight: 400;
    color: #fff;
    z-index: 2;
}

.services-card .services-card-icon {
    transition: all .7s ease;
    z-index: 2;
}

.services-card:hover .services-card-icon {
    rotate: 90deg;
}

.col-xl-3:nth-child(8) .services-card:hover .services-card-icon {
    rotate: 360deg;
}

.services-hover-bg {
    position: absolute;
    left: 0;
    z-index: 1;
    bottom: -300px;
    width: 100%;
    height: 100%;
    transition: all .4s ease;
}

.services-card:hover .services-hover-bg {
    bottom: 0;
}

.services-hover-bg img {
    width: 100%;
    height: 100%;
    position: relative;
}

.services-hover-bg::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
}

.accordion-button {
    background-color: #000;
    color: #fff;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-right: 125px;
    padding-left: 125px;
}

.accordion-item-1 .accordion-button.collapsed {
    background-image: url("assets/img/adv-bg.png");
}

.accordion-item-2 .accordion-button.collapsed {
    background-image: url("assets/img/adv-bg-2.png");
}

.accordion-item-3 .accordion-button.collapsed {
    background-image: url("assets/img/adv-bg-3.png");
}

.accordion-item-4 .accordion-button.collapsed {
    background-image: url("assets/img/adv-bg-4.png");
}

.accordion-item-5 .accordion-button.collapsed {
    background-image: url("assets/img/adv-bg-5.png");
}

.accordion-text {
    font-size: 36px;
}

.accordion-text-big {
    font-size: 52px;
}

.accordion-button::after {
    background-image: url("assets/img/plus.png") !important;
    width: 3rem;
    height: 3rem;
    background-size: cover;
}

.accordion-body {
    background-color: #000;
}

.accordion-body p {
    font-size: 30px;
    padding: 20px 100px;
    color: #fff;
}

.accordion-list {
    padding: 20px 100px;
    color: #fff;
    list-style: none;
}

.accordion-list li {
    border-bottom: 1px solid #fff;
    width: fit-content;
    font-size: 24px;
}

.accordion {
    --bs-accordion-border-color: none !important;
    --bs-accordion-border-radius: none !important;
    --bs-accordion-inner-border-radius: none !important;
}

.accordion-button:not(.collapsed) {
    background-color: #000;
    color: #fff;
}

.accordion-mobile-text {
    display: none;
}

.marquee-text {
    margin-top: 120px;
    font-size: 68px;
    border-bottom: 1px solid #000;
    padding-bottom: 25px;
}

.case-studies {
    margin-top: 150px;
}

.case-card {
    border: 1px solid #000;
    overflow: hidden;
}

.case-card h3 {
    color: #08327D;
    font-size: 32px;
}

.case-card p {
    font-size: 21px;
}

.case-card-hover {
    position: absolute;
    bottom: 50%;
    left: -350px;
    transform: translate(0, 50%);
    transition: all .5s ease;
}

.case-card:hover .case-card-hover {
    left: 10px;
}

.case-card-hover-badge {
    background-color: #08327D;
    color: #fff;
    opacity: .6;
    padding: 5px 12px;
    font-size: 20px;
}

.case-card-img-container {
    max-height: 293px;
    overflow: hidden;
}

.case-card img {
    transition: all .5s ease;
}

.case-card:hover img {
    transform: scale(1.2);
}

.tampap-case-card:hover img {
    transform: scale(1.1);
}

.antlara-case-card:hover img {
    transform: scale(1.02);
}

.case-prev-arrow {
    position: absolute;
    bottom: -60px;
    left: 15px;
    cursor: pointer;
    transition: all .3s ease;
}

.case-next-arrow {
    position: absolute;
    bottom: -60px;
    left: 80px;
    cursor: pointer;
    transition: all .3s ease;
}

.case-prev-arrow:hover,
.case-next-arrow:hover {
    color: #08327D;
    transform: scale(.8);
}

.quality-values-card .quality-values-card-title {
    font-size: 32px;
    font-weight: 500;
    transition: all .5s ease;
}

.quality-values-card-text {
    font-size: 24px;
}

.quality-values-card {
    position: relative;
    width: 100%;
    transition: all .5s ease;
}

.quality-values-card:hover {
    margin-left: -70px;
}

.quality-values-card:hover .quality-values-card-title {
    color: #08327D;
}

.quality-values-card-arrow {
    position: absolute;
    right: -50px;
    top: 0;
}

.quality-plus {
    width: 7%;
}

.quality-text {
    width: 63%;
}

.quality-title {
    width: 30%;
}

/* end homepage */

/* start footer */

#footer {
    border-top: 1px solid #000;
}

#footer span {
    font-size: 18px;
    font-weight: 500;
}

#footer h5 {
    font-size: 24px;
}

#footer span {
    margin-left: 10px;
}

#footer .footer-text {
    color: #08327D;
    font-weight: 500;
}

/* end footer */

/* start case studies */

.case-studies-title {
    font-size: 62px;
}

.case-studies-container {
    padding: 100px 0;
}

.col-xl-5 .case-card-hover {
    bottom: 55% !important;
}

.case-studies-card-container .col-xl-5:nth-child(1),
.case-studies-card-container .col-xl-5:nth-child(2) {
    margin-top: 50px;
}

.case-studies-card-container .col-xl-5 {
    margin-top: 125px;
}

/* end case studies */

/* start about us */

.about-text {
    font-size: 19px;
    font-weight: 500;
}

.about-last-text {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 125px;
}

.about-content-item {
    margin-top: 75px;
}

/* end about us */

/* start career */

.career-banner-container {
    padding: 100px 0;
}

.career-banner-title {
    font-size: 72px;
}

.career-banner-text {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
}

.career-card {
    border: 1px solid #000;
    padding: 30px;
    margin-top: 40px;
}

.career-card:last-child {
    margin-bottom: 125px;
}

.career-card-text {
    font-size: 22px;
    font-weight: 500;
}

.badge-blue {
    background-color: #08327D;
    color: #fff;
    width: fit-content;
    padding: 5px 10px;
}

/* end career */

/* start blog */

.blog-title {
    font-size: 62px;
}

.blog-container {
    padding: 100px 0;
}

.blog-content-container {
    margin-top: 75px;
}

.blog-card {
    background-image: url('assets/img/services-bg.png');
    background-size: cover;
    height: 100%;
}

.blog-card-body {
    color: #fff;
}

.blog-card-body h3 {
    color: #fff;
    font-weight: 400;
}

.blog-card-body p {
    font-size: 18px;
    font-weight: 400;
}

/* end blog */

/* start contact */

.underline-image {
    background-image: url('assets/img/underline.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.form-left-card {
    background-image: url('assets/img/services-bg.png');
    background-size: cover;
    border-right: 2px solid #000;
}

.contact-form {
    border: 2px solid #000;
    margin-bottom: 100px;
}

.form-control,
.col-xl-12.mt-4 label {
    border-radius: 0 !important;
    border: 1px solid #000;
    padding: .5rem .75rem;
}

.col-xl-12.mt-4 label {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.form-control:focus {
    border-color: #000;
    box-shadow: none;
}

.form-left-card-text {
    color: #fff;
    font-size: 22px;
}

.contact-person-text {
    font-size: 22px;
}

.contact-person-container {
    margin-bottom: 150px;
}

/* end contact */

/* start digital marketing */

.digital-marketing-banner {
    padding: 100px 0;
}

.marketing-span {
    background-image: url('/src/assets/img/circle.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.e-commerce-span {
    background-image: url('/src/assets/img/underline-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.website-span {
    background-image: url('/src/assets/img/underline-3.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.security-span {
    background-image: url('/src/assets/img/underline-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.data-int-span {
    background-image: url('/src/assets/img/circle-blue.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cloud-span {
    background-image: url('/src/assets/img/line-under.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.cloud-span-2 {
    background-image: url('/src/assets/img/line-under-2.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.comprehensive-card {
    background-image: url('/src/assets/img/big-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
}

.get-in-touch-card {
    background-image: url('/src/assets/img/big-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 175px 0;
    font-size: 28px;
    border-top: 1px solid #fff;
    overflow: hidden;
    transition: all .4s ease;
}

.get-in-touch-card:hover {
    text-decoration: underline;
    opacity: .5;
}

.get-in-touch-right-card {
    background-image: url('/src/assets/img/big-get-in-touch.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    border-top: 1px solid #fff;
}

.digital-marketing-drive-container,
.digital-marketing-services-container {
    padding: 75px 0;
}

.d-marketing-card {
    border: 1px solid black;
    border-radius: 3px;
    display: block;
    height: 238px;

    background: linear-gradient(to right, #08327D 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.d-marketing-card:hover {
    background-position: left bottom;
}

.text {
    color: black;
    transition: all .3s ease-out;
    display: block;
}

.text:hover {
    color: white;
}

.d-marketing-arrow {
    bottom: -30px !important;
}

#digital-marketing-accordion {
    padding: 150px 0 75px 0;
}

.d-m-accordion {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background-image: url('/src/assets/img/big-bg.png');
    background-size: cover;
    background-repeat: no;
}

.d-m-accordion .accordion-header {
    background-color: #fff;
}

.d-m-accordion .accordion-button {
    background-color: transparent;
    color: #000;
    padding: 0;
    height: 140px;
}

.d-m-accordion .accordion-text-big {
    font-size: 32px;
}

.d-m-accordion .accordion-body {
    background-color: transparent;
}

.d-m-accordion .accordion-body p {
    padding: 0;
}

.d-m-accordion .accordion-button::after {
    background-image: url('/src/assets/img/plus-black.png') !important;
}

.e-commerce-card-text {
    font-size: 32px;
}

.e-commerce-card-image img {
    position: absolute;
    right: -160px;
    top: 0;
    transition: all .5s ease;
    width: 450px;
    filter: grayscale(1);
}

.e-commerce-card {
    border: 1px solid #000;
    overflow: hidden;
    padding: 70px 40px;
}

.e-commerce-card:hover .e-commerce-card-image img {
    right: 0;
    filter: grayscale(0);
}

.e-commerce-card:hover p {
    color: #08327D;
    text-decoration: underline;
}

.e-commerce-plus {
    transition: all .5s ease;
}

.e-commerce-card:hover .e-commerce-plus {
    transform: rotate(45deg);
}

.e-commerce-table-container {
    margin-top: 125px;
    margin-bottom: 50px;
    background-image: url('/src/assets/img/e-commerce-table.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 1144px;
    width: 100%;
}

.solid-website {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -10%);
    width: 300px;
    height: 300px;
    background-color: #60779d;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.design-layout {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
    width: 300px;
    height: 300px;
    background-color: #60779d;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.better-navigation {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(10%, -50%);
    width: 300px;
    height: 300px;
    background-color: #60779d;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.optimized-website {
    position: absolute;
    right: 50%;
    bottom: 10%;
    transform: translate(50%, 10%);
    width: 300px;
    height: 300px;
    background-color: #60779d;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.e-commerce-table-container h3 {
    font-weight: 400;
}

.e-commerce-table-square {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62%;
}

.website-quote .first-row {
    position: relative;
}

.website-quote .first-row::after {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
}


.second-row {
    position: absolute;
    overflow: hidden;
    height: 220px;
    bottom: -200px;
    left: 125px;
    transition: all .5s ease;
}

.website-quote:hover .second-row {
    height: auto;
}

.second-row-text {
    font-size: 48px;
    color: #fff;
}

.second-row-sm-text {
    font-size: 22px;
    color: #fff;
}

.services-card-website {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 36px;
}

.attached-text {
    position: absolute;
    z-index: 123;
    bottom: 50px;
    left: 100px;
}

.attached-text p {
    font-size: 48px;
    color: #fff;
}

.data-int-card-body {
    background-image: url("/src/assets/img/big-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    border: 2px solid #000;
    border-top: 0;
}

.cloud-bg-card {
    background-image: url("/src/assets/img/services-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #000;
}

.cloud-icon-card {
    border: 2px solid #000;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.digital-marketing-subtitle {
    font-size: 42px;
}

.e-commerce-table-line {
    display: none;
}

.website-quote-btn-mobile {
    display: none;
}

.row-card-bg {
    height: 559px;
    border: 2px solid #000;
}

.row-card-bg2 {
    height: 533px;
    border: 2px solid #000;
}

.row-card-bg3 {
    height: 420px;
    border: 2px solid #000;
}

.digital-sec-risk-absolute-text {
    position: absolute;
    top: 0;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cloud-mng-optimize-text {
    font-size: 40px;
}

/* end digital marketing */

/* start impressum */

#impressum-container {
    padding: 100px 0;
}

#impressum-container h1 {
    font-size: 64px;
}

#impressum-container p {
    font-size: 20px;
}

.impressum-item {
    margin-top: 50px;
}

.impressum-item li {
    font-size: 20px;
}

/* end impressum */

@media screen and (max-width: 1200px) {
    .accordion-mobile-text-container {
        display: flex;
    }

    .accordion-text {
        display: flex;
        align-items: center;
    }

    .accordion-mobile-text {
        display: block;
    }

    .accordion-mobile-text p {
        font-size: 18px;
    }

    #mobileHeader .offcanvas-body ul li {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    #homepage {
        margin-top: 153px;
    }

    .services-card {
        height: 240px;
    }

    .services-card h5 {
        font-size: 26px;
    }

    .col-xl-3:nth-child(1) .services-card {
        border-bottom: none;
        border-left: none;
    }

    .col-xl-3:nth-child(2) .services-card {
        border-bottom: none;
    }

    .col-xl-3:nth-child(3) .services-card {
        border-left: none;
    }

    .col-xl-3:nth-child(4) .services-card {
        border-right: none;
    }

    .col-xl-3:nth-child(5) .services-card {
        border-left: none;
    }

    .col-xl-3:nth-child(7) .services-card {
        border-left: none;
    }

    .col-xl-3:nth-child(8) .services-card {
        border-right: none;
    }

    .accordion-button {
        padding-left: 20px;
        padding-right: 20px;
    }

    .accordion-mobile-text-container {
        display: flex;
    }

    .accordion-text {
        display: flex;
        align-items: center;
    }

    .accordion-mobile-text {
        display: none;
    }

    .accordion-mobile-text p {
        font-size: 18px;
    }

    .accordion-text-big {
        font-size: 36px;
    }

    .accordion-button::after {
        display: none;
    }

    .marquee-text {
        font-size: 32px;
    }

    .quality-values-card {
        flex-wrap: wrap;
    }

    .quality-plus {
        width: 11%;
    }

    .quality-title {
        width: 80%;
    }

    .quality-text {
        width: 100%;
        padding-right: 0 !important;
        margin-top: 10px;
    }

    .quality-values-card-text {
        font-size: 20px;
    }

    .quality-values-card .quality-values-card-title {
        font-size: 30px;
    }

    .case-card-hover {
        bottom: 70%;
    }

    .case-studies-all-btn {
        display: none;
    }

    .case-card:hover img {
        transform: scale(1);
    }

    .case-studies-card-container {
        margin: 0;
    }

    .col-xl-5 .case-card-hover {
        bottom: 68% !important;
    }

    .case-studies-card-container .col-xl-5 {
        margin-top: 50px !important;
    }

    #homepage,
    #case-studies-inner,
    #about-us-container,
    #career-container,
    #blog-container,
    #contant-container,
    #digital-marketing-container,
    #e-commerce-marketing-container,
    #website-development-container,
    #digital-security-container,
    #data-intelligence-container,
    #cloud-management-container,
    #creative-content-design {
        margin-top: 153px !important;
    }

    .career-banner-title {
        font-size: 60px;
    }

    .career-card-width {
        width: 100% !important;
    }

    .career-card-view-job,
    .career-card-view-job .btn {
        width: 100%;
    }

    .blog-small-card {
        display: none;
    }

    .banner-title {
        font-size: 40px !important;
    }

    .banner-title img {
        width: 50% !important;
    }

    .contact-form .form-left-card {
        border: none;
    }

    .contact-form-submit-btn .btn {
        width: 100%;
    }

    .get-in-touch-right-card {
        height: 250px;
    }

    .digital-marketing-subtitle {
        font-size: 32px;
    }

    .e-commerce-card-image img {
        right: 0;
        width: 100%;
        height: 225px;
    }

    .e-commerce-card {
        padding: 245px 15px 27px 15px;
    }

    .e-commerce-plus {
        display: none;
    }

    .e-commerce-card-text {
        font-size: 22px;
    }

    .solid-website,
    .better-navigation,
    .optimized-website,
    .design-layout {
        width: 175px;
        height: 175px;
    }

    .e-commerce-table-square {
        display: none;
    }

    .design-layout {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .optimized-website {
        bottom: 34%;
    }

    .better-navigation {
        right: 50%;
        top: 83%;
        transform: translate(50%, -50%);
    }

    .e-commerce-table-line {
        display: block;
        position: absolute;
        left: 50%;
        top: 23%;
        height: 600px;
    }

    .website-quote-mobile-img {
        display: none;
    }

    .website-quote {
        position: relative;
    }

    .second-row {
        height: 420px;
        top: 0;
        left: 0;
    }

    .second-row-text {
        font-size: 20px;
    }

    .second-row-sm-text {
        font-size: 16px;
    }

    .website-quote:hover .website-quote-btn {
        display: none;
    }

    .website-quote:hover .second-row {
        height: 420px !important;
    }

    .website-quote-btn-mobile {
        display: block;
    }

    .services-card-website {
        left: 50%;
        top: 50%;
        font-size: 24px;
        width: 358px;
    }

    .row-card-bg,
    .row-card-bg2,
    .row-card-bg3 {
        height: 200px;
    }

    .website-col-card-row {
        flex-direction: column-reverse;
    }

    .mobile-d-none {
        display: none;
    }

    .digital-sec-row-mobile {
        flex-direction: column-reverse;
    }

    .attached-text p {
        font-size: 20px;
    }

    .attached-text {
        bottom: 10px;
        left: 0;
    }

    .attached-absolute-txt {
        flex-direction: column;
    }

    .risk-mobile-text p {
        font-size: 20px !important;
    }

    .digital-sec-risk-absolute-text {
        width: 100%;
    }

    .risk-absolute-img {
        height: 400px;
    }

    .get-in-touch-card {
        padding: 110px 0;
    }

    .data-int-card-body {
        padding: 20px;
    }

    .data-int-big-img {
        height: 150px;
    }

    .data-int-numbers-card {
        flex-direction: column-reverse;
    }

    .cloud-mng-optimize-text {
        font-size: 26px;
    }

    .cloud-icon-card {
        height: 170px;
    }

    .text-end.website-quote-btn {
        display: none;
    }

    .website-row-card-column {
        flex-direction: column-reverse;
    }

    .products-designer-banner-right {
        display: none;
    }

    .machine-learning-banner {
        width: 250%;
    }

    #machine-learning-container {
        overflow-x: hidden;
    }

    .creative-banner-bg-image {
        height: 700px;
    }

    .creative-banner-text-container {
        max-width: 100% !important;
        padding: 30px !important;
        bottom: 50% !important;
        transform: translate(0%, 50%);
    }

    .creative-card-width {
        width: 100% !important;
    }

    .creative-slider-btn {
        margin-top: 150px !important;
    }

    .banner-slider-background {
        left: 35%;
        bottom: -28px;
    }

    .banner-slider-background img {
        width: 145%;
    }

    .banner-slider-background-text {
        left: 72%;
        bottom: 20px;
    }

    #banner {
        height: calc(100vh - 124.5px);
    }

    .banner-alignment {
        padding-top: 50px;
    }

    .d-mobile-none {
        display: none;
    }

    .offcanvas.offcanvas-bottom {
        height: 75% !important;
    }
}

.creative-content-slider-container {
    background-image: url("/src/assets/img/big-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 125px;
    margin-bottom: 75px;
}

.creative-arrow {
    bottom: -65px !important;
}

.creative-content-slider-container .container {
    padding: 100px 0;
    color: #fff;
}

.creative-slider-item:hover {
    border: 1px solid #fff;
}

#creative-content-design-container {
    margin-top: 200px;
}

#creative-content-design-container h1 {
    font-size: 78px;
    position: relative;
}

.creative-content-banner-text {
    font-size: 22px;
    font-weight: 500;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
}

.creative-banner-span::after {
    position: absolute;
    background-image: url("assets/img/creative-content-text-bg.png");
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    bottom: -65px;
    background-repeat: no-repeat;
}

.creative-banner-text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    max-width: 60%;
    padding: 100px;
}

.creative-banner-text-container h3 {
    font-size: 52px;
}

.creative-banner-text-container p {
    font-size: 28px;
}

.creative-banner-text-container img {
    opacity: .5;
}

.hover-underline:hover {
    text-decoration: underline;
}

/* update */

.homepage-slider-case-studies .slick-slide {
    width: 600px !important;
}

.digital-marketing-services-container .slick-slide {
    width: 570px !important;
}

.customer-item img {
    filter: grayscale(1);
    transition: all .3s ease;
}

.customer-item img:hover {
    filter: grayscale(0);
}

.accordion-header {
    transition: all .3s ease;
}

.accordion-header:hover {
    background-color: #08327D;
}

.accordion-header:hover .accordion-text-big {
    color: #fff;
}

.offcanvas.offcanvas-bottom {
    height: 500px;
}

#cv,
#additional {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.data-ing-container h2 {
    transition: all .3s ease;
}

.data-ing-container:hover h2 {
    padding-left: 35px !important;
    text-decoration: underline;
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .banner-slider-background img {
        width: 60%;
    }
}

@media (min-width: 1000px) and (max-width: 1024px) {
    .banner-slider-background img {
        width: 80%;
    }

    .banner-slider-background {
        left: 50%;
    }

    .banner-slider-background-text {
        left: 52%;
    }
}

@media screen and (max-width: 1200px) {
    .homepage-slider-case-studies .slick-slide {
        width: 366px !important;
    }

    .digital-marketing-services-container .slick-slide {
        width: 358px !important;
    }

    .mobile-dropdown-services-li {
        margin: 10px 20px 40px 20px;
    }

    .mobile-dropdown-services-li a {
        font-size: 20px;
        color: #0f0a01;
    }
}

.header-logo-animation {
    max-height: 56px;
    overflow: hidden;
}

.header-logo-animation img {
    transition: all .5s ease;
}

.header-logo-animation:hover img {
    margin-top: 20px;
}