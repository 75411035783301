$xs: 450px;
$md: 768px;
$lg: 1280px;
$xl: 1440px;
$transition-duration: 700ms;

.App {
  overflow: hidden;
}

.slider {
  width: 100%;
  height: calc(100vh - 88px);
  background: url("../../../assets/img/slider-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 2px solid #0f0a01;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    box-sizing: content-box;
  }

  .slider-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 400px);

    @media screen and (max-width: $lg) {
      height: calc(100% - 340px);
    }

    @media screen and (max-width: $md) {
      height: calc(100% - 300px);
    }

    @media screen and (max-width: $xs) {
      height: calc(100% - 220px);
    }

    // margin-top: 50px;
    // @media screen and (max-width: $lg) {
    //   margin-top: 50px;
    // }
    // @media screen and (max-width: $md) {
    //   margin-top: 80px;
    // }
    // @media screen and (max-width: $xs) {
    //   margin-top: 120px;
    // }
    h1 {
      margin: 0;
      font-size: 5rem;
      font-weight: 500;
      text-align: center;
      color: #0f0a01;
      transition: all;
      transition-duration: 400ms;

      @media screen and (max-width: $lg) {
        font-size: 3.75rem;
      }

      @media screen and (max-width: $md) {
        font-size: 3.125rem;
      }

      @media screen and (max-width: $xs) {
        font-size: 2.5rem;
      }

      span {
        filter: opacity(0.85);
        background: linear-gradient(
          to right,
          #8cde4b 0%,
          #b84bde 30%,
          #00a3ff 50%,
          #8cde4b 100%
        );
        transition: all;
        transition-duration: 1s;
        background-size: 200% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // animation: shine 1s linear infinite;
        // @keyframes shine {
        //   to {
        //     background-position: 200% center;
        //   }
        // }
      }
    }
    .slider-subtitle {
      p {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        padding: 0px 30px;

        @media screen and (max-width: $xs) {
          font-size: 15px;
        }
      }
    }
  }
  .animation-area {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: -2px;
    width: 620px;
    height: 400px;

    @media screen and (max-width: $lg) {
      width: 500px;
      height: 340px;
    }

    @media screen and (max-width: $md) {
      width: 480px;
      height: 300px;
    }

    @media screen and (max-width: $xs) {
      width: 100%;
      height: 220px;
    }

    .slider-text {
      display: -webkit-inline-box;
      width: 400px;
      height: 40px;
      overflow: hidden;
      margin-top: 20px;

      p {
        width: 100%;
        text-align: center;
        font-size: 30px;
        margin: 0;
        margin-bottom: 5px;
        white-space: nowrap;
        transition: all ease-in-out;
        transition-duration: $transition-duration;

        @media screen and (max-width: $xs) {
          font-size: 25px;
        }
      }
    }
    .slider-circle {
      position: absolute;
      bottom: 0;
      width: 620px;
      height: 310px;
      border-radius: 310px 310px 0 0;
      border: 2px solid #0f0a01;
      border-bottom-color: #fff;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $lg) {
        width: 500px;
        height: 250px;
        border-radius: 250px 250px 0 0;
      }

      @media screen and (max-width: $md) {
        width: 450px;
        height: 225px;
        border-radius: 225px 225px 0 0;
      }

      @media screen and (max-width: $xs) {
        width: 300px;
        height: 150px;
        border-radius: 150px 150px 0 0;
      }

      .colorfull-bg {
        width: 600px;
        height: 300px;
        border-radius: 300px 300px 0 0;
        bottom: -1px;
        position: absolute;
        display: flex;
        justify-content: center;
        background: linear-gradient(
          to right,
          #ea8d8d 0%,
          #fef8ec 50%,
          #a890fe 100%
        );
        animation: backgroundAnimaiton 4s ease infinite;

        @media screen and (max-width: $lg) {
          width: 490px;
          height: 245px;
          border-radius: 245px 245px 0 0;
        }

        @media screen and (max-width: $md) {
          width: 440px;
          height: 220px;
          border-radius: 220px 220px 0 0;
        }

        @media screen and (max-width: $xs) {
          width: 290px;
          height: 145px;
          border-radius: 145px 145px 0 0;
        }

        @keyframes backgroundAnimaiton {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .slider-circle-inner {
          width: 580px;
          height: 290px;
          border-radius: 290px 290px 0 0;
          border: none;
          background-color: #08327d;
          position: absolute;
          bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          @media screen and (max-width: $lg) {
            width: 480px;
            height: 240px;
            border-radius: 240px 240px 0 0;
          }

          @media screen and (max-width: $md) {
            width: 430px;
            height: 215px;
            border-radius: 215px 215px 0 0;
          }

          @media screen and (max-width: $xs) {
            width: 280px;
            height: 140px;
            border-radius: 140px 140px 0 0;
          }

          .text-in-circle {
            position: absolute;
            bottom: 0px;

            @media screen and (max-width: $xs) {
              bottom: 0px;
            }

            p {
              color: #fff;
              font-size: 35px;
              text-align: center;

              @media screen and (max-width: $xs) {
                font-size: 20px;
              }
            }
          }

          .lines {
            width: 510px;
            height: 510px;
            position: absolute;
            bottom: -260px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in-out;
            transition-duration: $transition-duration;

            @media screen and (max-width: $xs) {
              width: 250px;
              height: 250px;
              bottom: -130px;
            }

            .line {
              width: 60px;
              border: 2px solid #fff;
              position: absolute;

              @media screen and (max-width: $xs) {
                width: 30px;
                border: 1px solid #fff;
              }
            }
            .line:nth-child(1) {
              top: 30px;
              rotate: 90deg;

              @media screen and (max-width: $xs) {
                top: 15px;
              }
            }
            .line:nth-child(2) {
              right: 0px;
            }
            .line:nth-child(3) {
              bottom: 30px;
              rotate: 90deg;
              @media screen and (max-width: $xs) {
                bottom: 15px;
              }
            }
            .line:nth-child(4) {
              left: 0px;
            }
          }
        }
      }
    }
  }
}

@keyframes textbganimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
